import { graphql } from "gatsby";
import { ReactElement } from "react";

import Diseases from "../../../organisms/health-questionnaire/Diseases";
import OnboardingLayout from "../../../organisms/lead/OnboardingLayout";

const DisesasesPage = (): ReactElement => (
  <OnboardingLayout>
    <Diseases />
  </OnboardingLayout>
);

export default DisesasesPage;
export const query = graphql`
  query DiseasesPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
