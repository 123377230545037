import cn from "classnames";
import { Form, FormikHelpers, FormikProps } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ChangeEvent, Fragment, ReactElement, useState } from "react";

import Button from "../../atoms/button/Button";
import Checkbox, { CheckboxVariantName } from "../../atoms/checkbox/Checkbox";
import Heading from "../../atoms/heading/Heading";
import TextFormControl from "../../atoms/text-form-control/TextFormControl";
import { useLead, useSetLead } from "../../features/leads-funnel/application/lead-use-cases";
import { Lead } from "../../features/leads-funnel/domain/lead";
import { useCountry } from "../../func-core/core/application/hooks/useCountry";
import { Events, track } from "../../utils/analytics";
import { rollbar } from "../../utils/rollbar";
import PageStepForm from "../page-step-form/PageStepForm";
import * as styles from "./Diseases.module.scss";

interface DiseasesFormValues {
  petDiseases: string[];
  hasOtherDisease: boolean;
  otherDisease: string;
}

const Diseases = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const lead = useLead();
  const setLead = useSetLead();
  const { country } = useCountry();
  const [showOtherDiseaseField, setShowOtherDiseaseField] = useState<boolean>(false);

  const diseasesTranslations = i18n.getResourceBundle(
    country?.language.code,
    "translation"
  ) as Record<string, any>;
  if (!diseasesTranslations) return <></>;
  const diseasesList = Object.entries(diseasesTranslations)
    .filter(([key]) => key.startsWith(`health_questionnaire.diseases.${lead.petSpecies}.item_`))
    .map(([key, value]) => {
      const match = key.match(/item_\d+\.(.+)/);
      if (!match) return null;

      const diseaseId = match[1].replace(/_/g, "-");

      return { title: value, diseaseId };
    })
    .filter((item) => item !== null) as Array<{ title: string; diseaseId: string }>;

  const nextUrl: string = `/${i18n.language}/onboarding/email/`;

  const handleCheckboxChange = (
    diseaseId: string,
    props: FormikProps<DiseasesFormValues>,
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { checked } = event.target;
    const newDiseases = checked
      ? [...(props.values.petDiseases || []), diseaseId]
      : props.values.petDiseases?.filter((disease) => disease !== diseaseId) || [];
    void props.setFieldValue("petDiseases", newDiseases);
  };

  const handleOtherDiseaseCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    props: FormikProps<DiseasesFormValues>
  ): void => {
    const { checked } = event.target;
    void props.setFieldValue("hasOtherDisease", checked);
    setShowOtherDiseaseField(checked);
  };

  const diseasesData = {
    initialValues: {
      petDiseases: lead.petDiseases || [],
      hasOtherDisease: false,
      otherDisease: "",
    },
    handleSubmit: (
      values: DiseasesFormValues,
      { setSubmitting }: FormikHelpers<DiseasesFormValues>
    ): void => {
      if (!setLead) {
        return;
      }

      setSubmitting(true);
      setLead({ petDiseases: values.petDiseases })
        .then((updatedLead: Lead | undefined): void => {
          if (!updatedLead) {
            setSubmitting(false);

            return;
          }

          track(Events.FORM_ANSWERED, {
            healthQuestionnaire: {
              question: "Diseases",
              answer: values.hasOtherDisease
                ? updatedLead.petDiseases?.concat(values.otherDisease)
                : updatedLead.petDiseases,
              hasOtherDisease: values.hasOtherDisease,
            },
          });

          setTimeout(() => navigate(nextUrl), 500);
        })
        .catch((): void => {
          rollbar.warn("There was an error setting the lead.");
          setSubmitting(false);
        });
    },
    children: (props: FormikProps<DiseasesFormValues>) => {
      const { isSubmitting, isValid, values } = props;

      return (
        <Form className={cn(styles.diseasesForm)}>
          <ul>
            {diseasesList.map(({ title, diseaseId }) => (
              <li key={diseaseId}>
                <Checkbox
                  id={diseaseId}
                  name={diseaseId}
                  onChange={(event) => handleCheckboxChange(diseaseId, props, event)}
                  isChecked={values.petDiseases?.includes(diseaseId) || false}
                  adoptionClassName={styles.diseaseCheckbox}
                  variantName={CheckboxVariantName.rounded}
                >
                  {title}
                </Checkbox>
              </li>
            ))}
            <li className={styles.otherDiseaseWrapper}>
              <input
                type="checkbox"
                id={"hasOtherDisease"}
                name={"hasOtherDisease"}
                onChange={(event) => handleOtherDiseaseCheckboxChange(event, props)}
                checked={values.hasOtherDisease}
              />
              <label htmlFor={"hasOtherDisease"}>
                {t("health_questionnaire.diseases.other_disease.label")}
              </label>
              {showOtherDiseaseField && (
                <TextFormControl
                  name={"otherDisease"}
                  label={t("health_questionnaire.diseases.other_disease.field.label")}
                  adoptionClassName={styles.otherDiseaseField}
                  maxLength={100}
                  isFocusedOnRender
                />
              )}
            </li>
          </ul>

          <section className={styles.diseasesDisclaimer}>
            <Heading level={2}>{t("health_questionnaire.diseases.disclaimer.title")}</Heading>
            <ul>
              {[1, 2, 3, 4].map((item, index, array) => {
                const text = t(`health_questionnaire.diseases.disclaimer.element.${item}.text`, {
                  petName: lead.petName,
                });
                const iconHref =
                  index === array.length - 1 ? "#icon-rounded-cross" : "#icon-radio-checked";

                return (
                  <Fragment key={item}>
                    {text && (
                      <li>
                        <svg className={styles.icon} role="img" aria-hidden="true">
                          <use href={iconHref}></use>
                        </svg>
                        <span>{text}</span>
                      </li>
                    )}
                  </Fragment>
                );
              })}
            </ul>
          </section>
          <Button type="submit" disabled={isSubmitting || !isValid} isLoading={isSubmitting}>
            {t("common.cta.next")}
          </Button>
        </Form>
      );
    },
  };

  return (
    <PageStepForm
      title={t("health_questionnaire.diseases.question", { petName: lead.petName })}
      formData={diseasesData}
    />
  );
};

export default Diseases;
